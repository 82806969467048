const NationalMomentumData = [
    {
        id: "DistribuciónProductos",
        text: "Distribución de productos a Nivel Nacional.",
        imgUrl: "distribucion.png",    
    },
    {
        id: "Comercio",
        text: "Comercialización en sucursales y a través de vendedores aliados.",
        imgUrl: "comercio.png",   
    },
    {
        id: "PoliticaCorporativa01", 
        text: "Asesoría Técnica Profesional.",
        imgUrl: "asesoria.png",   
    },


]

export { NationalMomentumData }