
//Banners Main
import CalbosA      from '../static/images/catalogoCalbos/01 Calbos.jpg';
import CalbosB      from '../static/images/catalogoCalbos/02 Calbos.jpg';
import CalbosC      from '../static/images/catalogoCalbos/03 Calbos.jpg';
import CalbosD      from '../static/images/catalogoCalbos/04 Calbos.jpg';
import CalbosE      from '../static/images/catalogoCalbos/05 Calbos.jpg';
import CalbosF      from '../static/images/catalogoCalbos/06 Calbos.jpg';
import CalbosG      from '../static/images/catalogoCalbos/07 Calbos.jpg';
import CalbosH      from '../static/images/catalogoCalbos/08 Calbos.jpg';
import CalbosI      from '../static/images/catalogoCalbos/09 Calbos.jpg';
import CalbosJ      from '../static/images/catalogoCalbos/10 Calbos.jpg';
import CalbosK      from '../static/images/catalogoCalbos/11 Calbos.jpg';
import CalbosL      from '../static/images/catalogoCalbos/12 Calbos.jpg';
import CalbosM      from '../static/images/catalogoCalbos/13 Calbos.jpg';
import CalbosN      from '../static/images/catalogoCalbos/14 Calbos.jpg';


export const CatalogoCalbos = [
    {
        id:'001',
        img:CalbosA,
    },
    {
        id:'002',
        img:CalbosB,
    },
    {
        id:'003',
        img:CalbosC,
    },
    {
        id:'004',
        img:CalbosD,
    },
    {
        id:'005',
        img:CalbosE,
    },
    {
        id:'006',
        img:CalbosF,
    },
    {
        id:'007',
        img:CalbosG,
    },
    {
        id:'008',
        img:CalbosH,
    },
    {
        id:'009',
        img:CalbosI,
    },
    {
        id:'010',
        img:CalbosJ,
    },
    {
        id:'011',
        img:CalbosK,
    },
    {
        id:'012',
        img:CalbosL,
    },
    {
        id:'011',
        img:CalbosM,
    },
    {
        id:'012',
        img:CalbosN,
    },
]

