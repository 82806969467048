import React from 'react';

//imagenes
import nosotros from 'static/images/home/aboutUsHome.webp';

//Estilos y diseño
import './AboutUsHome.css'
import 'aos/dist/aos.css'; 


const AboutUsHome = () => {


  return (
    <>
      <div className='body-container'>

        {/* ----------- Desktop ----------------*/}
        <div className='us-container' >

            {/* Info */}
            <div className='us-wrap-Info'
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-once="true" 
                data-aos-duration="800"
            >

                  <h1 className='title-basic-start'> 
                    De la mano del productor  
                    <span style={{color:'#489B1E'}}> Venezolano</span> 
                  </h1>
                  
                <div className='us-text'>
                    <p className='text-basic'>
                        Somos una compañía sólida con mas de 70 años de trayectoria, 
                        reconocidos por nuestro compromiso, responsabilidad y servicio 
                        brindado colaborando con el sector agroalimentario del país.
                    </p>
                    <a href='/company' className=''>
                      <button className='btn-outline btn-text main-green'
                          >Ver más
                      </button>
                    </a>
                </div>
            </div>

            {/* Imagen Principal */}
            <div
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-once="true" 
              data-aos-duration="800"
            >
              <a className='us-wrap' >
                  <img
                    className='us-img'
                    alt='Sede AgroMax'
                    src={ nosotros }
                  />
              </a>

            </div>
        </div>
      </div>
    </>
  )
}

export default AboutUsHome;

