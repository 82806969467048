export const op = [
  {
    id:1,
    option: "Inicio",
    value: true,
    href:'/cw_site/1/',
    icon:'BsCart3'
  },
  {
    id:2,
    option: "Empresa",
    value: true,
    href:'/company',
  },
  {
    id:3,
    option: "Contacto",
    value: true,
    href:'/contact'
  },

];